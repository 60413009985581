import { Environment } from './environment.model';

export const environment: Environment = {
    fayeUrl: 'https://ws.baresto.sf.preprod.spiriit.tech',
    name: 'staging',
    tokenCookieName: 'JWT',
    sentryDsn: 'https://e9896b5224d2482dac6b1883ef9c9db2@sentry.infra.choosit.net/8',
    sentryRelease: '2024.11.26',
    analytics: 'GTM-WMNRMLK',
    leadInfo: 'LI-6666D2C7420E6',
    markerIo: {
        project: '671f9f367a3b33b4b92d8582',
    },
    release: '2024.11.26',
    barestoUser: 'démobaresto',
    agrimer: {
        baseUrl: 'https://baresto-agrimer.sf.preprod.spiriit.tech',
        token: '2Z3QkAiC52zC+pG#9oF0k|Ak!YKVt}l+r-UT1GNOP0-kkLOvGmRL1Gac',
    },
};
